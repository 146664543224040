import React from "react";


function DC002() {
  return (
    <a
      totd-track-event="KBA Dream Chimney Demo Tracks Vol. 2"
      href="https://dreamchimney.bandcamp.com/album/dream-chimney-demo-tracks-vol-2"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="//www.dreamchimney.com/mainpage/kba/DC003-kba.jpg"
        alt="Dream Chimney Demo Tracks Vol. 2"
        border="0"
        className="img-responsive"
      />
    </a>
  );
}
function DC003() {
  return (
    <a
      totd-track-event="KBA Dream Chimney Demo Tracks Vol. 2"
      href="https://dreamchimney.bandcamp.com/album/dream-chimney-demo-tracks-vol-2"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="//www.dreamchimney.com/mainpage/kba/DC003-kba.jpg"
        alt="Dream Chimney Demo Tracks Vol. 2"
        border="0"
        className="img-responsive"
      />
    </a>
  );
}
function DC004() {
  return (
    <a
      totd-track-event="KBA Dream Chimney Demo Tracks Vol. 3"
      href="https://dreamchimney.bandcamp.com/album/dream-chimney-demo-tracks-vol-3"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="//www.dreamchimney.com/mainpage/kba/DC004-kba.jpg"
        alt="Dream Chimney Demo Tracks Vol. 3"
        border="0"
        className="img-responsive"
      />
    </a>
  );
}
function DC006() {
  return (
    <a totd-track-event="Air Waves" href="https://dreamchimney.bandcamp.com/album/air-waves" target="_blank" rel="noopener noreferrer">
      <img
        src="//www.dreamchimney.com/mainpage/kba/DC006-kba-animated.gif"
        alt="Dream Chimney presents Air Waves"
        border="0"
        className="img-responsive"
      />
    </a>
  );
}
function DC007() {
  return (
    <a
      totd-track-event="Sentimental Memories"
      href="https://dreamchimney.bandcamp.com/album/sentimental-memories-ep"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="//www.dreamchimney.com/mainpage/kba/DC007-kba-720.jpg" alt="Sentimental Memories" border="0" className="img-responsive" />
    </a>
  );
}

function DC010() {
  return (
    <a totd-track-event="Matter of Time" href="https://dreamchimney.bandcamp.com/album/matter-of-time" target="_blank" rel="noopener noreferrer">
      <img src="//www.dreamchimney.com/mainpage/kba/kba-dc010-cd.jpg" alt="RIK - Matter of Time" border="0" className="img-responsive" />
    </a>
  );
}

function DC011Version2() {
  return (
    <a totd-track-event="Smoothsayer EP" href="https://addtocart.dreamchimney.com/DC011" target="_blank" rel="noopener noreferrer">
      <img
        src="//www.dreamchimney.com/mainpage/kba/DC011-kba-v2.jpg"
        alt="Hormone - Smoothsayer EP"
        border="0"
        className="img-responsive"
      />
    </a>
  );
}
function DC012() {
  return (
    <a
      totd-track-event="Bubble Funk EP"
      href="https://dreamchimney.bandcamp.com/album/bubble-funk"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="//www.dreamchimney.com/mainpage/kba/DC012-kba.jpg" alt="Sorcerer - Bubble Funk EP" border="0" className="img-responsive" />
    </a>
  );
}
function DC015() {
  return (
    <a totd-track-event="Distant Paradise" href="https://dreamchimney.bandcamp.com/album/distant-paradise" target="_blank" rel="noopener noreferrer">
      <img src="//www.dreamchimney.com/mainpage/kba/DC015-kba.jpg" alt="Distant Paradise" border="0" className="img-responsive" />
    </a>
  );
}
function DCMerch() {
  return (
    <a href="https://dreamchimney.bigcartel.com/" target="_blank" rel="noopener noreferrer">
      <img
        src="https://www.dreamchimney.com/mainpage/kba/dc-merch-all.gif"
        className="img-responsive img-fluid"
        alt="Dream Chimney Merch"
      />
    </a>
  );
}
function DCTieDyeShirts() {
  return (
    <a
      totd-track-event="dc-shirts-tie-dye"
      href="https://dreamchimney.bandcamp.com/merch/dc-crystal-tie-dyed-t-shirt-limited"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="https://www.dreamchimney.com/mainpage/kba/kba-tiedye.jpg" className="img-responsive img-fluid" alt="Dream Chimney Tie Dye Shirts" />
    </a>
  );
}
function DCShirts() {
  return (
    <a
      totd-track-event="dc-shirts"
      href="https://dreamchimney.bigcartel.com/product/dream-chimney-dc-shirt"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="https://www.dreamchimney.com/mainpage/kba/kba-shirts.jpg" className="img-responsive img-fluid" alt="Dream Chimney Shirts" />
    </a>
  );
}
function DCMotw500() {
  return (
    <a
      totd-track-event="dc-motw-500"
      href="https://soundcloud.com/the-dream-chimney/mix-of-the-week-500-albion-mixtura-of-the-week"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://www.dreamchimney.com/mainpage/kba/motw500.jpg"
        className="img-responsive img-fluid"
        alt="Dream Chimney Mix of the Week 500"
      />
    </a>
  );
}
function DCMotwWeekly() {
  return (
    <a
      totd-track-event="dc-motw-555"
      href="https://soundcloud.com/the-dream-chimney/mix-of-the-week-555-felix-dickinson"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://www.dreamchimney.com/mainpage/kba/motw555.jpg"
        className="img-responsive img-fluid"
        alt="Dream Chimney Mix of the Week"
      />
    </a>
  );
}

function DCSlipmats() {
  return (
    <a
      totd-track-event="dc-mats"
      href="https://dreamchimney.bigcartel.com/product/dream-chimney-slipmat"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://www.dreamchimney.com/mainpage/kba/kba-slipmats.jpg"
        className="img-responsive img-fluid"
        alt="Dream Chimney Turntable Slipmats"
      />
    </a>
  );
}

function CdAndLpAff1() {
  return (
    <a
      totd-track-event="cdandlp"
      href="https://www.cdandlp.com?lng=2&affilie=dreamchimney&utm_source=www.dreamchimney.com&utm_medium=banner&utm_campaign=affiliation"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://www.dreamchimney.com/mainpage/kba/kba-cdandlp.jpg"
        className="img-responsive img-fluid"
        alt="CD and LP - Music Marketplace"
      />
    </a>
  );
}

{/* <iframe id="embedPlayer" src="https://embed.music.apple.com/us/album/extended-away/1403562257?i=1403562265&amp;app=music&amp;itsct=totd_box&amp;itscg=30200&amp;at=10lsNn&amp;ls=1&amp;theme=light" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style="width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);"></iframe> */ }

function JunoBanner1() {
  return (
    <div>
      <a href="http://www.junodownload.com/latestcampaign/?ref=dc" target="_blank">
        <img src="http://images.juno.co.uk/banners/affiliate/latestcampaign/jd_latestcampaign_728x90.gif" alt="Juno Download latest campaign" title="Juno Download latest campaign" width="728" height="90" />
      </a>
      <img src="https://affiliate.juno.co.uk/scripts/imp.php?ref=dc&amp;a_bid=fdeba759" width="1" height="1" alt="" />
    </div>
  );
}


function SENSound() {
  return (
    <a href="https://www.sentrallsound.com/" target="_blank" rel="noopener noreferrer">
      <img src="//www.dreamchimney.com/mainpage/kba/sentrallSound.jpg" className="img-responsive" alt="Sentrall Sound" />
    </a>
  );
}
function Radio() {
  return (
    <a totd-track-event="DCRadio" href="//www.dreamchimney.com/radio" target="_blank" rel="noopener noreferrer">
      <img src="//www.dreamchimney.com/mainpage/kba/DCRadio-kba.jpg" alt="Dream Chimney Radio" border="0" className="img-responsive" />
    </a>
  );
}
function DC014() {
  return (
    <a totd-track-event="DC014" href="https://dreamchimney.bandcamp.com/album/auspicious-beginnings" target="_blank" rel="noopener noreferrer">
      <img src="//www.dreamchimney.com/mainpage/kba/DC014-pre-order-kba.jpg" alt="Tommy Awards" border="0" className="img-responsive" />
    </a>
  );
}
function DCApp() {
  return (
    <a totd-track-event="dc-app" href="https://apple.co/3R7i4FX" target="_blank" rel="noopener noreferrer">
      <img
        src="https://www.dreamchimney.com/mainpage/kba/DCApp-kba-720.jpg"
        alt="Dream Chimney App"
        border="0"
        className="img-responsive"
      />
    </a>
  );
}

// usestate crashes on /tracks , not sure why
function DCMotw() {
  return (
    <a totd-track-event="dc-motw" href="//www.dreamchimney.com/motw/" target="_blank" rel="noopener noreferrer">
      <img
        src="https://www.dreamchimney.com/mainpage/kba/kba-motw.gif"
        alt="Dream Chimney Mix of the Week"
        border="0"
        className="img-responsive"
      />
    </a>
  );
}
Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

const banners = [
  // <DCMotw />,
  // <CdAndLpAff1 />,
  // <JunoBanner1 />,
  // <CdAndLpAff3 />,
  <DCMerch />,
  <DCShirts />,
  <DCTieDyeShirts />,
  // <DCSlipmats />,
  <SENSound />,
  // <DC004 />,
  <DC006 />,
  // <DC007 />,
  <Radio />,
  <DC010 />,
  <DC015 />,
  // <DCApp />,
  // <DC011Version2 />,
  // <DC011 />,
  // <DC012 />,
  // <DC013 />,
  <DC014 />,
  // <DCMotw500 />,
  <DCMotwWeekly />
];
const bannersRadio = [
  <DCTieDyeShirts />,
  <DCShirts />,
  <DCMotw500 />,
  <DCMotwWeekly />,
  <DC007 />,
  <DCMerch />,
  // <DCApp />,
  <DC010 />,
  <DC015 />,
  // <DC012 />,
  // <DC013 />,
  <DC014 />,
  <SENSound />,
  // <JunoBanner1 />,
];
const bannersByDestination = {
  tracks: banners,
  interview: banners,
  radio: bannersRadio,
  homepage: banners,
  promos: banners,
};

const selectedBanner = (destination) => bannersByDestination[destination] || bannersByDestination.tracks;

const Kba = ({ destination }) => (
  <div style={{ display: "block", textAlign: "center", margin: "10px auto" }}>{selectedBanner(destination).random()}</div>
);

export const AllBanners = () => (
  <div style={{ display: "block", textAlign: "center", margin: "10px auto" }}>
    {banners.map((banner) => (
      <div style={{ display: "block", textAlign: "center", margin: "30px auto" }}>{banner}</div>
    ))}
  </div>
);

export default Kba;
